import { Route, Routes, useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import CustomRoutes from "./Config/Routes";
import { useStores, StoreContext } from "./stores";
import { Login } from "./Pages";
import BaseLayout from "./Components/Layout/BaseLayout";
import { Auth } from "aws-amplify";
import { publicCampaignClient, setupApiClient } from "./api";
import Admins from "./Pages/Admins";
import { Layout, PageLoader } from "./Components";
import { useEffect, useState } from "react";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

function App() {
  // setInterval(async () => {
  //   try {
  //     const cognitoUser = await Auth.currentAuthenticatedUser();
  //     const { refreshToken } = cognitoUser.getSignInUserSession();
  //     cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
  //       setupApiClient(session.idToken.jwtToken);
  //     });
  //   } catch (e) {
  //     throw e;
  //   }
  // }, 1200000);

  const navigate = useNavigate();

  const stores = useStores();

  const { route, user, authStatus } = useAuthenticator((context) => [context.authStatus, context.user]);

  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    let user = await Auth.currentAuthenticatedUser();
    setProfile(user.attributes.profile);
  };

  useEffect(() => {

    if (authStatus === 'authenticated') {

      const signInSession = user.getSignInUserSession();

      if (signInSession) {
        publicCampaignClient.setAuthorization(signInSession.getIdToken().getJwtToken())
      }

    }

  }, [authStatus]);

  if (authStatus === "configuring") {
    return <BaseLayout><PageLoader /></BaseLayout>
  }


  return (
    <StoreContext.Provider value={stores}>
      <Routes>
        {CustomRoutes}
      </Routes>
    </StoreContext.Provider>
  )
}

export default App;
