import { useEffect, useMemo, useState } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { publicCampaignClient } from "../../api";
import { PageLoader } from "../../Components";
import { filter, get, indexOf, isArray, map, reduce } from "lodash";
import dayjs from "dayjs";
import { getDaysBetween2Dates, getPastMonths, secondsToTime } from "../../helpers/utils";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Button } from "react-bootstrap";
import { Value } from "@wojtekmaj/react-daterange-picker/dist/cjs/shared/types";

type StatsClientType = { PSN: number, HOU: number, CO: number, GLOBAL: number }

type Stats = { [key: string]: any } & {
  avg_time: { avg_sec: number, avg_confirm_count: number };
  book_count: StatsClientType;
  cancel_count: number;
  confirm_count: number;
  invite_client: number;
  reschedule_count: number;
  open_count: number;
  user_count: number;
  invite_count: StatsClientType;
  sms_sent: StatsClientType;
  sms_signup: StatsClientType;
};

const USER_TYPE = ["DS", "PHN", "ET"]

function Overview() {

  const [dateRange, setDateRange] = useState<Value>([dayjs().startOf('month').toDate(), new Date()]);

  const [statsQueries, setStatsQueries] = useState<Stats[]>([]);

  const [loading, setLoading] = useState(true)
 

  useEffect(()=>{

    const getStatsReport = async ()=>{
      setLoading(true);
      try {
        const  statsReport = await Promise.all(USER_TYPE.map(type=>publicCampaignClient.call(
          "overallreport",
          {
            start_date: dateRange && isArray(dateRange) && dayjs(dateRange[0]).format("YYYY-MM-DD"),
            end_date:  dateRange && isArray(dateRange) && dayjs(dateRange[1]).format("YYYY-MM-DD"),
            type,
          }
        )))
        
        const isFailed =  map(statsReport, 'status').filter(v=>v === "failed");

        if (isFailed.length > 0){
          return []
        }
       
        const stats = map(statsReport, 'result');
  
        setStatsQueries(stats);
        setLoading(false);
      } catch (error) {
        setLoading(false)
      }
      
    }

    if (dateRange){

      getStatsReport()

    }  


  }, [dateRange])  

  const overAll = useMemo(() => {

    if (statsQueries.length > 0) {

      const totalValues = map(statsQueries, function (m) { return Object.values(m)[0] })

      const getTotal = (stats: Stats[], type: string) => {
        return reduce(stats, function (sum, n) { return sum + n[type] }, 0)
      }

      const getClientTypeCountTotal = (stats: Stats[], type: string) => {

        const typeCount = map(stats, function (m) { return m[type] });

        return reduce(typeCount, function (sum, n) {
          return { PSN: sum.PSN + n.PSN, HOU: sum.HOU + n.HOU, CO: sum.CO + n.CO, GLOBAL: sum.GLOBAL + n.GLOBAL }
        }, { PSN: 0, HOU: 0, CO: 0, GLOBAL: 0 })

      }

      const getAvgTimeTotal = (stats: Stats[]) => {

        const timeCount = map(stats, function (m) { return m.avg_time });
       
        const totalAvg = reduce(timeCount, function (sum, n) {
        
          return { avg_sec: sum.avg_sec + n.avg_sec, avg_confirm_count: sum.avg_confirm_count + n.avg_confirm_count }
        }, { avg_sec: 0, avg_confirm_count: 0 })

        return {
          avg_sec: totalAvg.avg_sec / USER_TYPE.length,
          avg_confirm_count: totalAvg.avg_confirm_count / USER_TYPE.length,
        }

      }

      return {
        avg_time: getAvgTimeTotal(totalValues),
        book_count: getClientTypeCountTotal(totalValues, "book_count"),
        cancel_count: getTotal(totalValues, 'cancel_count'),
        confirm_count: getTotal(totalValues, 'confirm_count'),
        invite_client: getTotal(totalValues, 'invite_client'),
        reschedule_count: getTotal(totalValues, 'reschedule_count'),
        open_count: getTotal(totalValues, 'open_count'),
        user_count: getTotal(totalValues, 'user_count'),
        invite_count: getClientTypeCountTotal(totalValues, "invite_count"),
        sms_sent: getClientTypeCountTotal(totalValues, "sms_sent"),
        sms_signup: getClientTypeCountTotal(totalValues, "sms_signup"),
      }

    }

    return null
  }, [statsQueries])

  if (!overAll || loading) {
    return <PageLoader />
  }

  const getAvgTime = (avg: {avg_sec: number, avg_confirm_count: number}) => {

   

    if (avg.avg_confirm_count > 0){
      return secondsToTime(Math.floor(
        avg.avg_sec / avg.avg_confirm_count
      ))
    }else{
      return "N/A"
    }

  }

  const renderStats = (stats: Stats) => {

    return <table className="table table-borderless">
      <tbody>
        <tr><td className="border-0">Avg. time from Invite to Booking</td><td>{ getAvgTime(stats.avg_time) }</td></tr>
        <tr><td className="border-0">No. of Meeting Invitations</td><td>{stats.invite_client}</td></tr>
        <tr><td className="border-0">No. of opened meeting Invitations</td><td>{stats.open_count}</td></tr>
        <tr><td className="border-0">No. of Bookings</td><td>{stats.confirm_count}</td></tr>
        <tr><td className="border-0">No. of Reschedules</td><td>{stats.reschedule_count}</td></tr>
        <tr><td className="border-0">No. of Cancellations</td><td>{stats.cancel_count}</td></tr>
        <tr><td className="border-0">Client breakdown of invites</td><td>{`Person ${stats.invite_count.PSN}, Household ${stats.invite_count.HOU}, Company ${stats.invite_count.CO}, ClientChoice ${stats.invite_count.GLOBAL}`}</td></tr>
        <tr><td className="border-0">Client breakdown of bookings</td><td>{`Person ${stats.book_count.PSN}, Household ${stats.book_count.HOU}, Company ${stats.book_count.CO}, ClientChoice ${stats.book_count.GLOBAL}`}</td></tr>
        <tr><td className="border-0">Active Users</td><td>{stats.user_count}</td></tr>
        <tr><td className="border-0">No. of sign ups to SMS reminders</td><td>{reduce(Object.values(stats.sms_signup), function (sum, v) { return sum + v }, 0)}</td></tr>
        <tr><td className="border-0">No. of SMS messages sent</td><td>{reduce(Object.values(stats.sms_sent), function (sum, v) { return sum + v }, 0)}</td></tr>
      </tbody>
    </table>
  }


  return (
    <>
      <div className="main__head">
        <h2 className="main__title"></h2>
      </div>
      <div className="main__body main__body--flex main__body--flex-alt">
        <div className="row align-items-center justify-content-center">
          <div className="col-6 align-self-center text-center">
            <DateRangePicker onChange={setDateRange} value={dateRange} />
          </div> 
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div>
                <div className="divider__horizontal">
                  <div className="divider__text">Overall</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-8 align-self-center text-center">
              {renderStats(overAll)}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div>
                <div className="divider__horizontal">
                  <div className="divider__text">Dominion Securities</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-8 align-self-center text-center">
              {renderStats(get(filter(statsQueries, s => Object.keys(s)[0] === "DS")[0], "DS"))}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div>
                <div className="divider__horizontal">
                  <div className="divider__text">Philips Hager, North</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-8 align-self-center text-center">
              {renderStats(get(filter(statsQueries, s => Object.keys(s)[0] === "PHN")[0], "PHN"))}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div>
                <div className="divider__horizontal">
                  <div className="divider__text">Royal Trust</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-8 align-self-center text-center">
              {renderStats(get(filter(statsQueries, s => Object.keys(s)[0] === "ET")[0], "ET"))}
            </div>
          </div>
        </div>


      </div>
    </>
  )
}

export default Overview;
