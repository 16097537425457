import { Route, Navigate } from "react-router-dom";
import {
  NoMatch,
  Overview,
  Login,
} from "../Pages";
import { BaseLayout, Layout } from "../Components";

export default (
  <>
   <Route path="/login" element={<BaseLayout><Login /></BaseLayout>} />
    <Route path="/" element={<Layout />}>
      <Route index element={<Navigate to="/overview" />} />
      <Route path="overview" element={<Overview />} />
      {/* <Route path="participants" element={<Participants />} />
      <Route path="participant/:key" element={<Participant />} />
      <Route path="winners" element={<Winners />} /> */}
      {/* <Route path="inventory" element={<Inventory />} /> */}
      <Route path="*" element={<NoMatch />} />
    </Route>
   
  </>
);
